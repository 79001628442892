import styles from './HeaderV2.module.scss';
import CurrentHour from '../CurrentHour';
import { useAuthStore } from 'src/Stores/AuthStore';
import { IconPersonRoundFilled, PhoneChat } from '@assets/icons/icons';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import HeaderMissionSecondary from './HeaderMissionSecondary';
import { useRef, useState } from 'react';
import ContextMenu from './ContextMenu';
import { useOnClickOutside } from 'usehooks-ts';
import useCurrentUser from 'src/Hooks/useCurrentUser';
import HeaderSynchroSelfCare from './HeaderSynchroSelfCare';

export default function HeaderV2({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const contextMenuRef = useRef(null);

  const [contextMenuIsOpen, setContextMenuIsOpen] = useState(false);
  const { missionContext, mission } = useCurrentMission();
  const user = useCurrentUser();
  const auth = useAuthStore();

  useOnClickOutside(contextMenuRef, () => {
    setContextMenuIsOpen(false);
  });

  const displaySecondaryMissionHeader = missionContext && missionContext.currentStep !== "search";
  const displaySelfCareSynchroHeader = !displaySecondaryMissionHeader && missionContext?.isSelfCare && missionContext?.forceLockAction;
  const hasSelfCareSynchro = missionContext?.isSelfCare && missionContext?.forceLockAction;

  return (
    <>
      <div className={[
        styles.container,
        styles.placeholder,
        displaySecondaryMissionHeader ? styles.withMissionSecondaryHeader : null,
        hasSelfCareSynchro ? styles.withSelfCareSynchro : null,
      ].join(" ")} />

      <div className={[styles.container, styles.containerFixed, className].join(" ")} {...containerProps}>
        <div className={styles.contentAbsoluteContainer}>

          <div className={styles.contentAbsoluteContentContainer}>
            <CurrentHour />

            {mission && mission.clientFirstname && mission.clientLastname && (
              <div className={styles.beneficiaryInfoContainer}>
                <PhoneChat className={styles.phoneChatIcon}/>
                <div className={styles.beneficiaryName}>
                  {mission.clientFirstname.substring(0,1)}.{mission.clientLastname}
                </div>
              </div>
            )}

            <div className={styles.findSolutionText}>
            </div>

            {auth.isAuthenticated() && (
              <div className={styles.agentInfoContainer} ref={contextMenuRef}>
                <div
                  className={styles.agentInfoContentContainer}
                  onClick={() => setContextMenuIsOpen(!contextMenuIsOpen)}
                  data-testid="agent-info"
                >
                  <div className={styles.agentName}>
                    {user?.firstName?.substring(0,1) || ""}.{user?.lastName || ""}
                  </div>
                  <IconPersonRoundFilled className={styles.profileIcon}/>
                </div>

                {contextMenuIsOpen && (
                  <ContextMenu
                    containerProps={{
                      className: styles.contextMenu,
                    }}
                  />
                )}
              </div>
            )}
          </div>

          {displaySecondaryMissionHeader && (
            <HeaderMissionSecondary />
          )}

          {displaySelfCareSynchroHeader && (
            <div className='px-[70px] pb-4 bg-white'>
              <HeaderSynchroSelfCare />
            </div>
          )}
        </div>
      </div>
    </>
  )
}