import { useTranslation } from 'react-i18next';
import styles from './SearchForm.module.scss';
import stylesForm from '../Form.module.scss';
import Checkbox from '@components/Checkbox/Checkbox';
import Switch from '@components/Switch/Switch';
import HourPicker from '@components/HourPicker/HourPicker';
import DatePicker from '@components/DatePicker/DatePicker';
import InputText from '@components/Inputs/InputText/InputText';
import Select from '@components/Select/Select';
import Button from '@components/Button/Button';
import React, { useState, useEffect, useCallback, useMemo, useImperativeHandle } from 'react';
import ModalOptions from './Modals/Options';
import ModalDriver from './Modals/Driver';
import ModalPassengers from './Modals/Passengers/Passengers';
import OriginDestInput from '@components/OriginDestInput/OriginDestInput';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { IconAddPassenger, IconLight, LuggageBig, LuggageSmall } from '@assets/icons/icons';
import DateHourPicker from '@components/DateHourPicker/DateHourPicker';
import { isSelfCare } from 'src/Stores/AppContext';
import useFormDates from './useFormDates';
import { TransportTypes } from 'src/Stores/CurrentMissionTypes/Types';
import { SuggestionPlaceType } from 'src/models/Place';


type OptionsType = {
  driver?: {
    disabled: boolean;
  };
  passenger?: {
    canAddCard: boolean;
  };
  options?: {
    disabled: boolean;
  };

  noRemainingCosts?: {
    hide?: boolean;
    canChange?: boolean;
    defaultValue?: boolean;
  }
};

export type SearchFormHandle = {
  getFormData: () => Promise<{
    [key: string]: any;
  }>;
};

const SearchForm = React.forwardRef<SearchFormHandle, {
  options?: OptionsType;
  readOnly?: boolean;
  onChangeSearchLoading?: (loading: boolean) => any;
  containerProps?: React.HTMLAttributes<HTMLFormElement>
}>(({
  options,
  readOnly,
  onChangeSearchLoading,
  containerProps: { className, ...containerProps } = {},
}, ref) => {
  const { t } = useTranslation();

  const [displayErrors, setDisplayErrors] = useState(false);
  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const { missionContext, callAction } = useCurrentMission();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState<"options" | "driver" | "passenger" | null>(null);
  const searchForm = missionContext?.steps.search?.formData;

  const ageCategories = missionContext?.steps.search?.form?.ageCategories || [];
  const defaultVrentalDays = missionContext?.steps.search?.form?.defaultVrentalDays;

  const formDates = useFormDates({
    datetime: searchForm?.datetime,
    returnDatetime: searchForm?.returnDatetime,
    defaultVrentalDays,
    maxDurationDays: isSelfCare() ? searchForm?.maxDurationDays : undefined,
  });

  const {
    form,
    setForm,
    setMode,
  } = useForm({
    options
  });

  const {
    formDate,
    setFormDate,
    minDate,
    minDateReturn,
    minHour,
    minMinutes,
    minHourReturn,
    minMinutesReturn,
    maxDateReturn,
    maxHourReturn,
    maxMinutesReturn,
    nbDays,
    maxDays,
    onChangeNbDays,
  } = formDates;

  const onlyOrigin = !form.transportTypes?.train && !form.transportTypes?.taxi && form.returnVrentalInOriginAgency;

  const checkErrors = useCallback((formToCheck: typeof form) => {
    if (!displayErrors) {
      return;
    }
    const onlyOrigin = !formToCheck.transportTypes?.train && !formToCheck.transportTypes?.taxi && formToCheck.returnVrentalInOriginAgency;
    const errors: {[key: string]: string} = {};

    if (!onlyOrigin && !formToCheck.destination && !formToCheck.origin) {
      errors.origin = t('valid_origin_destination');
    } else if (!formToCheck.origin) {
      errors.origin = t('valid_origin');
    }
    if (!onlyOrigin && !formToCheck.destination) {
      errors.destination = t('valid_destination');
    }

    setErrors(errors);
  }, [displayErrors, t]);

  const onSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return await onSubmit();
  }

  const onSubmit = async (returnData = false) => {

    setDisplayErrors(true);

    try {

      if (form.origin?.id === form.destination?.id && (form.transportTypes?.taxi || form.transportTypes?.train)) {
        setErrors({
          origin: t('valid_origin_destination'),
        });
        return;
      }

      if (!onlyOrigin && !form.destination && !form.origin) {
        setErrors({
          origin: t('valid_origin_destination'),
        });
        return;
      } else if (!form.origin) {
        setErrors({
          origin: t('valid_origin'),
        });
        return;
      } else if (!onlyOrigin && !form.destination) {
        setErrors({
          destination: t('valid_destination'),
        });
        return;
      }

      const data: any = {}
      data.driver = form.driver;
      data.transportTypes = form.transportTypes;

      data.destination = form.destination;
      data.noRemainingCosts = form.noRemainingCosts;
      data.returnVrentalInOriginAgency = form.returnVrentalInOriginAgency;

      if (form.origin?.isCurrentLocation && form.origin?.latitude && form.origin?.longitude) {
        data.originGeolocalized = {
          latitude: form.origin.latitude,
          longitude: form.origin.longitude,
        }
      } else {
        data.origin = form.origin;
      }

      if (form.transportTypes?.vrental && !form.transportTypes?.taxi && !form.transportTypes?.train && form.returnVrentalInOriginAgency) {
        data.destination = form.origin;
      }

      const dateCloned = new Date(formDate.date || new Date());
      dateCloned.setHours(formDate.hour || 0);
      dateCloned.setMinutes(formDate.minutes || 0);
      dateCloned.setSeconds(0);
      data.datetime = dateCloned.getFullYear()+'-'+`${dateCloned.getMonth()+1}`.padStart(2, '0')+'-'+`${dateCloned.getDate()}`.padStart(2, '0')+'T'+`${dateCloned.getHours()}`.padStart(2, '0')+':'+`${dateCloned.getMinutes()}`.padStart(2, '0')+':00';

      if (form.transportTypes?.vrental) {
        const dateReturnCloned = new Date(formDate.dateReturn || new Date());
        dateReturnCloned.setHours(formDate.hourReturn || 0);
        dateReturnCloned.setMinutes(formDate.minutesReturn || 0);
        dateReturnCloned.setSeconds(0);
        data.returnDatetime = dateReturnCloned.getFullYear()+'-'+`${dateReturnCloned.getMonth()+1}`.padStart(2, '0')+'-'+`${dateReturnCloned.getDate()}`.padStart(2, '0')+'T'+`${dateReturnCloned.getHours()}`.padStart(2, '0')+':'+`${dateReturnCloned.getMinutes()}`.padStart(2, '0')+':00';
      }

      data.passengers = form.passengers;
      data.vrentalOptions = form.vrentalOptions;
      data.taxiOptions = form.taxiOptions;

      data.baggage = form.baggage;

      if (returnData) {
        return data;
      }

      const submitAction = missionContext?.steps.search?.submitAction;

      if (submitAction) {
        await callAction(submitAction, data, {
          onStatusChange: (status) => {
            if (status === "loading") {
              setLoading(true);
              onChangeSearchLoading && onChangeSearchLoading(true);
            }
          }
        });
      }

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      onChangeSearchLoading && onChangeSearchLoading(false);
    }
  }

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      return onSubmit(true);
    },
  }));

  useEffect(() => {
    setForm(form => ({
      ...form,
      transportTypes: searchForm?.transportTypes || {},
    }));

  }, [searchForm, setForm]);

  const countEnabledTransportTypes = useMemo(() => {
    return (Object.keys(searchForm?.allowedTransportTypes || {}) as TransportTypes[]).map((key) => {
      return searchForm?.allowedTransportTypes?.[key] ? 1 : 0;
    }).reduce((acc: number, value) => acc + value, 0)
  }, [searchForm?.allowedTransportTypes]);

  return (
    <form
      {...containerProps}
      className={[styles.container, stylesForm.form, className].join(" ")}
      onSubmit={onSubmitForm}
      data-testid='search-form'
    >

      {modalOpen === "options" && (
        <ModalOptions
          readOnly={readOnly}
          transportTypes={form.transportTypes || {}}
          options={{
            vrentalOptions: { ...form.vrentalOptions },
            taxiOptions: { ...form.taxiOptions }
          }}
          onChange={(options) => {
            setForm({
              ...form,
              vrentalOptions: { ...options.vrentalOptions },
              taxiOptions: { ...options.taxiOptions }
            });
          }}
          onRequestClose={() => setModalOpen(null)}
        />
      )}
      {modalOpen === "driver" && (
        <ModalDriver
          readOnly={readOnly}
          onRequestClose={() => setModalOpen(null)}
          defaultValues={{
            has_old_licence: form.driver.has_old_licence,
            has_manual_licence: form.driver.has_manual_licence,
            has_bank_card: form.driver.has_bank_card,
          }}
          onChange={(options) => {
            setForm({
              ...form,
              driver: options,
            });
          }}
        />
      )}
      {(modalOpen === "passenger") && (
        <ModalPassengers
          readOnly={readOnly}
          passengers={form.passengers}
          canAddCards={options?.passenger?.canAddCard !== false}
          onChange={(passengers) => {
            setForm({
              ...form,
              passengers,
            });
          }}
          onRequestClose={() => setModalOpen(null)}
        />
      )}

      <div className={stylesForm.formRowBlock}>
        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol].join(" ")}>
            <span className={styles.title}>
              {isSelfCare() ? t('transport_principal') : t('selectionner_modes_de_transports_principaux')}
            </span>
          </div>
        </div>

        <div className={[stylesForm.formRow].join(" ")}>
          {Object.keys(form.transportTypes || {}).map((keyStr: string) => {
            const key = keyStr as TransportTypes;
            if (searchForm?.allowedTransportTypes?.[key] === false) return null;
            if (key === "walk" || key === "arrival") return null;

            const keyToLabel = {
              train: t('train'),
              vrental: t('vehicule_de_location'),
              taxi: t('taxi'),
            };

            return (
              <Checkbox
                key={key}
                disabled={readOnly || loading || countEnabledTransportTypes === 1}
                label={keyToLabel[key]}
                testid={`checkbox-transport-type-${key}`}
                checked={form.transportTypes?.[key] === true}
                onChange={(checked) => {
                  setMode(key, checked);
                  checkErrors({
                    ...form,
                    transportTypes: {
                      ...form.transportTypes,
                      [key]: checked,
                    }
                  });
                }}
              />
            );
          })}
        </div>

        <div className={`${stylesForm.formRow} mt-8`}>
          <OriginDestInput
            testid='origin-dest-input'
            readOnly={readOnly || loading}
            errorMsg={errors.origin || errors.destination}
            onlyOrigin={!form.transportTypes?.train && !form.transportTypes?.taxi && form.returnVrentalInOriginAgency}
            disabled={readOnly || loading}
            defaultOrigin={form.origin}
            defaultDestination={form.destination}
            mode={isSelfCare() ? 'modal' : 'inline'}
            onChangeDestination={(destination) => {
              setForm((form) => ({ ...form, destination }));
              setErrors(({ destination: _dest, ...errors }) => errors);
            }}
            onChangeOrigin={(origin) => {
              setForm((form) => ({ ...form, origin }));
              setErrors(({ origin: _ori, ...errors }) => errors);
            }}
            containerProps={{ className: "w-full" }}
          />
        </div>

        {form.transportTypes?.vrental && (
          <div className={`${stylesForm.formRow} mt-8`}>
            <Checkbox
              disabled={readOnly || loading}
              testid='return-vehicle-in-origin-agency'
              label={t('retour_vehicle_agency')}
              checked={form.returnVrentalInOriginAgency}
              onChange={(checked) => {
                setForm({ ...form, returnVrentalInOriginAgency: checked });
                checkErrors({ ...form, returnVrentalInOriginAgency: checked });
              }}
            />
          </div>
        )}
      </div>

      <div className={stylesForm.formRowBlock}>
        {isSelfCare() && (
          <>
            <div className={stylesForm.formRow}>
                <DateHourPicker
                  containerProps={{
                    className: "w-full"
                  }}
                  label={t('date_depart')}
                  disabled={loading}
                  date={formDate.date}
                  onChangeDate={(date) => {
                    if (date === null) {
                      return;
                    }
                    setFormDate(formDate => ({ ...formDate, date }));
                  }}
                  minDate={minDate}

                  hour={formDate.hour}
                  minute={formDate.minutes}
                  onChangeHour={(hour) => {
                    if (hour === null) {
                      return;
                    }
                    setFormDate(formDate => ({ ...formDate, hour }));
                  }}
                  onChangeMinutes={(minutes) => {
                    if (minutes === null) {
                      return;
                    }
                    setFormDate(formDate => ({ ...formDate, minutes }));
                  }}
                  minHour={minHour}
                  minMinutes={minMinutes}
                />
            </div>
            {form.transportTypes?.vrental && (
            <div className={stylesForm.formRow}>
                <DateHourPicker
                  containerProps={{ className: "w-full" }}
                  label={isSelfCare() ? t('date_retour_vehicle') : t('fin_location_vehicle')}
                  disabled={loading}
                  date={formDate.dateReturn}
                  onChangeDate={(dateReturn) => { setFormDate(formDate => ({ ...formDate, dateReturn })); }}
                  minDate={minDateReturn}
                  hour={formDate.hourReturn}
                  minute={formDate.minutesReturn}
                  minHour={minHourReturn}
                  minMinutes={minMinutesReturn}

                  maxDate={maxDateReturn}
                  maxHour={maxHourReturn}
                  maxMinutes={maxMinutesReturn}
                  onChangeHour={(hourReturn) => { setFormDate(formDate => ({ ...formDate, hourReturn })); }}
                  onChangeMinutes={(minutesReturn) => { setFormDate(formDate => ({ ...formDate, minutesReturn })); }}
                />
            </div>
            )}
            {(form.transportTypes?.vrental && searchForm?.maxDurationDays !== undefined && searchForm.maxDurationDays > 0) && (
            <div className={styles.maxDurationDaysContainer}>
              <div>
                <IconLight />
              </div>
              <div className={styles.maxDurationText}>
                {t('prolongation_conditions', { days: searchForm.maxDurationDays, plural: searchForm.maxDurationDays > 1 ? 's' : '' })}
              </div>
            </div>
            )}
          </>
        )}

        {!isSelfCare() && (
        <>
          <div className={[stylesForm.formRow].join(" ")}>
            <div className={[stylesForm.formCol].join(" ")}>
              {t('departure')}
            </div>
            <div className={[stylesForm.formCol].join(" ")}>
              <DatePicker
                testid='date-picker-startdate'
                disabled={readOnly || loading}
                date={formDate.date}
                onChange={(date) => {
                  if (date === null) {
                    return;
                  }
                  setFormDate(formDate => ({ ...formDate, date }));
                }}
                minDate={minDate}
              />
              <span className={styles.dateHourSeparator}>{t('at')}</span>
              <HourPicker
                testid='hour-picker-startdate'
                disabled={readOnly || loading}
                hour={formDate.hour}
                minutes={formDate.minutes}
                minHour={minHour}
                minMinutes={minMinutes}
                onChange={({ hour, minutes }) => {
                  if (hour === null || minutes === null) {
                    return;
                  }
                  setFormDate(formDate => ({
                    ...formDate,
                    hour,
                    minutes
                  }));
                }}
              />
            </div>
          </div>

          {form.transportTypes?.vrental && (
            <>
              <div className={[stylesForm.formRow].join(" ")}>
                <div className={[stylesForm.formCol].join(" ")}>
                  {t('nb_days_vrentals')}
                </div>
                <div className={[stylesForm.formCol].join(" ")}>
                  <Select
                    disabled={readOnly || loading}
                    optionsV2={Array.from({ length: maxDays || 300 }, (_, i) => i + 1).map((i) => ({
                      label: i.toString(),
                      value: i,
                    }))}
                    selectedIndex={nbDays - 1}
                    onChangeIndex={(index) => {
                      if (index === null) {
                        return;
                      }
                      onChangeNbDays(index + 1);
                    }}
                    containerProps={{
                      style: {
                        width: "94.5px",
                      }
                    }}
                  />
                </div>
              </div>
              <div className={[stylesForm.formRow].join(" ")}>
                <div className={[stylesForm.formCol].join(" ")}>
                  {t('fin_location_vehicle')}
                </div>
                <div className={[stylesForm.formCol].join(" ")}>
                  <DatePicker
                    disabled={readOnly || loading}
                    date={formDate.dateReturn}
                    onChange={(dateReturn) => {
                      setFormDate(formDate => ({ ...formDate, dateReturn }));
                    }}
                    minDate={minDateReturn}
                    maxDate={maxDateReturn}
                  />
                  <span className={styles.dateHourSeparator}>{t('at')}</span>
                  <HourPicker
                    testid='hour-picker-enddate'
                    disabled={readOnly || loading}
                    hour={formDate.hourReturn}
                    minutes={formDate.minutesReturn}
                    minHour={minHourReturn}
                    minMinutes={minMinutesReturn}
                    maxHour={maxHourReturn}
                    maxMinutes={maxMinutesReturn}
                    onChange={({ hour: hourReturn, minutes: minutesReturn }) => {
                      setFormDate(formDate => ({
                        ...formDate,
                        hourReturn,
                        minutesReturn
                      }));
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </>
        )}
      </div>

      {isSelfCare() ? (
        <div className={"flex flex-column w-full mb-4 items-center"}>
          <div className="mr-2 flex-1">
            <InputText
              variant="default"
              label={t("nombre_de_personnes")}
              readOnly
              testid='passenger-input'
              inputProps={{
                disabled: loading,
                style: {"cursor": "pointer"},
                value: (() => {

                  const passengers = form.passengers;
                  const byType = {young: 0, adult: 0, senior: 0};
                  passengers.forEach((passenger) => {
                    byType[passenger.ageCategory] += 1;
                  });

                  const values: string[] = [];
                  if (byType.adult > 0) {
                    values.push(`${byType.adult} adulte${byType.adult > 1 ? "s" : ""}`);
                  }
                  if (byType.young > 0) {
                    values.push(`${byType.young} jeune${byType.young > 1 ? "s" : ""}`);
                  }
                  if (byType.senior > 0) {
                    values.push(`${byType.senior} senior${byType.senior > 1 ? "s" : ""}`);
                  }

                  return values.join(", ");

                })(),
                onClick: () => {
                  setModalOpen("passenger");
                }
              }}
            />
          </div>
          <div className={styles.addPassengerIconButton} onClick={() => setModalOpen("passenger")}>
            <IconAddPassenger fill='white' />
          </div>
        </div>
      ) : (
        <div className={`${stylesForm.formRow} ${stylesForm.marginBottomBig}`}>
          <div className={[
            stylesForm.formCol,
            styles.formLabelContainer
          ].join(" ")}>
            {t('passagers')}{form.transportTypes?.vrental && <>* <br/></>}
            {form.transportTypes?.vrental && (
              <span className={styles.formLabelInfos}>{t('incl_conducteur')}</span>
            )}
          </div>
          <div className={[stylesForm.formCol].join(" ")}>
            <InputText
              variant='outlined'
              readOnly
              testid='passenger-input'
              inputProps={{
                disabled: loading,
                style: {
                  "cursor": "pointer",
                  ...(readOnly ? { "opacity": 0.5 } : {}),
                  "width": "150px",
                },
              value: (() => {

                const passengers = form.passengers;
                const byType = {young: 0, adult: 0, senior: 0};
                passengers.forEach((passenger) => {
                  byType[passenger.ageCategory] += 1;
                });

                function getLabel(ageCategory: 'adult' | 'young' | 'senior', count: number) {
                  const ageCat = ageCategories.find(cat => cat.id === ageCategory);
                  let label = "";
                  switch (ageCategory) {
                    case 'adult':
                      label = t('age_label_adult', { count, plural: count > 1 ? 's' : '' });
                      break;
                    case 'young':
                      label = t('age_label_young', { count, plural: count > 1 ? 's' : '' });
                      break;
                    case 'senior':
                      label = t('age_label_senior', { count, plural: count > 1 ? 's' : '' });
                      break;
                  }

                  if (ageCat?.ageMinimum !== undefined && ageCat?.ageMinimum !== null) {
                    label += ` (${ageCat.ageMinimum}${ageCat.ageMaximum ? ` - ${ageCat.ageMaximum}` : "+"})`;
                  }

                  return label;
                }

                const values: string[] = [];
                if (byType.adult > 0) {
                  values.push(getLabel('adult', byType.adult));
                }
                if (byType.young > 0) {
                  values.push(getLabel('young', byType.young));
                }
                if (byType.senior > 0) {
                  values.push(getLabel('senior', byType.senior));
                }

                return values.join(", ");

              })(),
                onClick: () => {
                  setModalOpen("passenger");
                }
              }}
            />
          </div>
        </div>
      )}

      {form.transportTypes?.vrental && !options?.driver?.disabled && (
        <div className={`${stylesForm.formRow} ${stylesForm.marginBottomBig}`}>

          <div className={[
            stylesForm.formCol,
            styles.formLabelContainer
          ].join(" ")}>
            {t('conducteur_location_vehicle')}<>* <br/></>
            <span className={styles.formLabelInfos}>{t('conducteur_location_vehicle_specify')}</span>
          </div>
          <div className={[stylesForm.formCol].join(" ")}>
            <InputText
              variant='outlined'
              readOnly
              inputProps={{
                disabled: loading,
                style: {
                  "cursor": "pointer",
                  ...(readOnly ? { "opacity": 0.5 } : {}),
                  "width": "150px",
                },
                placeholder: t("aucune_option"),
                value: (() => {
                    const driver = form.driver;
                    const values: string[] = [];
                    if (driver.has_old_licence) {
                      values.push(t('3_years_licence'));
                    }
                    if (driver.has_manual_licence) {
                      values.push(t('manual_gearbox'));
                    }
                    if (driver.has_bank_card) {
                      values.push("CB");
                    }
                    return values.join(", ");
                })(),
                onClick: () => {
                  setModalOpen("driver");
                }
              }}
            />
          </div>
        </div>
      )}

      {!isSelfCare() && (
      <div className={stylesForm.formRow}>
        <div className={[stylesForm.formCol].join(" ")}>
          {t('bagages')}
        </div>
      </div>
      )}

      <div className={`${stylesForm.formRow} ${stylesForm.marginBottomBig}`}>
        <div className={[stylesForm.formCol, 'flex-1'].join(" ")}>
          <LuggageSmall style={isSelfCare() ? { marginTop: "13px" } : {}}/>
          <Select
            label={isSelfCare() ? t('bagages_cabine') : undefined}
            disabled={readOnly || loading}
            options={isSelfCare() ? [
              "0",
              "1",
              "2",
              "3",
              "4",
              "5"
            ] : [
              t('none'),
              "1 " + t('small'),
              "2 " + t('smalls'),
              "3 " + t('smalls'),
              "4 " + t('smalls'),
              "5 " + t('smalls'),
            ]}
            selectedIndex={form.baggage.smallCount}
            onChangeIndex={(index) => {
              setForm({
                ...form,
                baggage: {
                  ...form.baggage,
                  smallCount: index || 0,
                }
              });
            }}
            containerProps={{
              className: 'w-full'
            }}
          />
        </div>
        <div className={[stylesForm.formCol, "flex-1"].join(" ")}>
          <LuggageBig style={isSelfCare() ? { marginTop: "13px" } : {}}/>
          <Select
            label={isSelfCare() ? t('bagages_soute') : undefined}
            disabled={readOnly || loading}
            options={isSelfCare() ? [
              "0",
              "1",
              "2",
              "3",
              "4",
              "5"
            ] : [
              t('none'),
              "1 " + t('big'),
              "2 " + t('bigs'),
              "3 " + t('bigs'),
              "4 " + t('bigs'),
              "5 " + t('bigs'),
            ]}
            selectedIndex={form.baggage.bigCount}
            onChangeIndex={(index) => {
              setForm({
                ...form,
                baggage: {
                  ...form.baggage,
                  bigCount: index || 0,
                }
              });
            }}
            containerProps={{
              className: 'w-full'
            }}
          />
        </div>
      </div>

      {(form.transportTypes?.vrental || form.transportTypes?.taxi ) && !options?.options?.disabled && (
        <div className={`${stylesForm.formRow} ${stylesForm.marginBottomBig}`}>
          <div className={[stylesForm.formCol, styles.formColLeftFixedWidth].join(" ")}>
            {t('selected_options')}
          </div>
          <div className={[stylesForm.formCol].join(" ")}>
            <InputText
              variant='outlined'
              readOnly
              inputProps={{
                disabled: loading,
                style: {"cursor": "pointer"},
                placeholder: t('aucune_option'),
                value: (() => {
                  const vrentalSelection = form.vrentalOptions;
                  const taxiSelection = form.taxiOptions;
                  const values: string[] = [];
                  const countTruthyOptions = (options: {[key: string]: any}) => {
                    const truthyValues = Object.values(options).filter(value => value !== undefined && value !== false && value !== "");
                    return truthyValues.length;
                  };
                  if(vrentalSelection){
                    const count = countTruthyOptions(vrentalSelection);
                    count > 0 && values.push(`Voiture: ${count} opt.`);
                  }
                  if(taxiSelection){
                    const count = countTruthyOptions(taxiSelection);
                    count > 0 && values.push(`Taxi: ${count} opt.`);
                  }
                  return values.join(", ");
                })(),
                onClick: () => {
                  setModalOpen("options");
                }
              }}
            />
          </div>
        </div>
      )}

      {options?.noRemainingCosts?.hide !== true &&(
        <div className={`${stylesForm.formRow} ${stylesForm.marginBottomBig}`}>
          <div className={[stylesForm.formCol].join(" ")}>
            {t('hideNoRemainingCharge')}
          </div>
          <div className={[stylesForm.formCol].join(" ")}>
            <Switch
              disabled={readOnly || loading || options?.noRemainingCosts?.canChange === false}
              checked={form.noRemainingCosts}
              onChange={() => { setForm({ ...form, noRemainingCosts: !form.noRemainingCosts }); }}
            />
          </div>
        </div>
      )}
      <div className={`${stylesForm.formRow} ${stylesForm.marginBottomBig}`}>
        <Button
          testid='button-search'
          label={loading ? t('changement_en_cours') : t('search')}
          containerProps={{
            className: styles.buttonSearch,
            disabled: readOnly || loading,
            type: "submit",
          }}
        />
      </div>

    </form>
  )
});


export default SearchForm;

function useForm({
  options
}: {
  options? : OptionsType;
}) {

  const { missionContext } = useCurrentMission();
  const searchForm = missionContext?.steps.search?.formData;

  const [form, setForm] = useState<{
    transportTypes: { [key in TransportTypes]?: boolean } | null | undefined;
    origin?: SuggestionPlaceType | null;
    destination?: SuggestionPlaceType | null;
    returnVrentalInOriginAgency?: boolean;

    noRemainingCosts: boolean;
    passengers: Array<{
      ageCategory: "adult" | "young" | "senior";
      age?: number;
      cards? : {
        [typeID: string]: {
          number: string;
        };
      }
    }>;
    driver: {
      has_old_licence: boolean;
      has_manual_licence: boolean;
      has_bank_card: boolean;
    };
    taxiOptions:  { [key: string]: any };
    vrentalOptions:  { [key: string]: any };
    baggage: {
      smallCount: number;
      bigCount: number;
    };

  }>({
    transportTypes: searchForm?.transportTypes,
    origin: searchForm?.origin || undefined,
    destination: searchForm?.destination || undefined,
    noRemainingCosts: searchForm?.noRemainingCosts || options?.noRemainingCosts?.defaultValue || false,
    taxiOptions: searchForm?.taxiOptions ? searchForm.taxiOptions : {},
    vrentalOptions: searchForm?.vrentalOptions ? searchForm.vrentalOptions : {},
    passengers: searchForm?.passengers || [{
      ageCategory: "adult"
    }],
    driver: {
      has_old_licence: false,
      has_manual_licence: false,
      has_bank_card: false,
      ...(searchForm?.driver || {})
    },
    returnVrentalInOriginAgency: searchForm?.returnVrentalInOriginAgency || false,

    baggage: {
      smallCount: searchForm?.baggage?.smallCount || 0,
      bigCount: searchForm?.baggage?.bigCount || 0,
    },
  });

  useEffect(() => {
    if (!form.destination?.id) {
      return;
    }
    setForm((form) => ({
      ...form,
      returnVrentalInOriginAgency: form.origin?.id === form.destination?.id
    }));
  }, [form.origin?.id, form.destination?.id])

  const setMode = (mode: "train" | "vrental" | "taxi", checked: boolean) => {
    setForm({
      ...form,
      transportTypes: {
        ...form.transportTypes,
        [mode]: checked,
      },
    });
  }

  return {
    form,
    setForm,
    setMode,
  }
}