import { GuaranteeDetailsType } from 'src/models/GuaranteeDetails';
import stylesForm from '../Form.module.scss';
import styles from './WarrantiesList.module.scss';
import Modal from '@components/Modal/Modal';
import WarrantiesListTable, { WarrantiesListTableProps } from '@components/WarrantiesList/WarrantiesList';
import { useTranslation } from 'react-i18next';

export default function ModalDetailsWarranties({
  onRequestClose,
  containerProps: { className, ...containerProps } = {},
  warrantiesListTableProps,
  guaranteeDetails
}: {
  onRequestClose?: () => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  warrantiesListTableProps: WarrantiesListTableProps;
  guaranteeDetails: GuaranteeDetailsType
}) {
  const { t } = useTranslation();

  return (
    <Modal
      onRequestClose={onRequestClose}
      title={t('modal.warranties.title')}
    >
      <div className={[stylesForm.form, styles.modalContentContainer, className].join(" ")} {...containerProps}>
        {/* <div className={[stylesForm.formRow, stylesForm.formTitleRow].join(" ")}>
          <div className={stylesForm.formCol}>
            {t('warranties.list')}
          </div>
        </div> */}

        {guaranteeDetails?.isEnvelope && (
          <div className={`${stylesForm.formRow} ${styles.enveloppeRow}`}>
            <div className={[stylesForm.formCol].join(" ")}>
              <span className={styles.envelopeTitle}>{t('envelope.title')}</span>
            </div>
            <div className={stylesForm.formCol}>
              {guaranteeDetails.envelope.availableAmount.formatted} {guaranteeDetails.envelope.availableAmount.currency.symbol}
            </div>
          </div>
        )}

        <div className={`${stylesForm.formRow} px-12`}>
          <WarrantiesListTable
            {...warrantiesListTableProps}
            containerProps={{
              ...(warrantiesListTableProps?.containerProps || {}),
              className: ["w-full", warrantiesListTableProps?.containerProps?.className || ""].join(" "),
            }}
            // displayHeader
            // layout='wide'
          />
        </div>

        {guaranteeDetails.claimHistory && guaranteeDetails.claimHistory.length > 0  && (
          <div className={styles.claimHistoryContainer}>
            <h2>{t('claimHistory.title')}</h2>
            <div className={styles.claimHistory}>
            {guaranteeDetails.claimHistory.map((guarantee) => (
              <p className={styles.claimHistoryRow} key={guarantee.timestamp} >
                <span>
                  {new Intl.DateTimeFormat(navigator.language, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }).format(new Date(guarantee.timestamp))}
                </span>

                <img src={guarantee.icon} />
                <span>{guarantee.label}</span>
                <span>{guarantee.amount.formatted} {guarantee.amount.currency.symbol}

                {(guarantee.nbVrentalDays || 0) !== 0 && (
                  <span> / {guarantee.nbVrentalDays} {Math.abs(guarantee.nbVrentalDays || 0) === 1 ? t('days.singular') : t('days.plural')}</span>
                )}
                </span>
              </p>
            ))}
             </div>
          </div>
        )}

      </div>
    </Modal>
  )
}
