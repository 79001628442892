import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import stylesForm from '@components/Form/Form.module.scss';
import styles from './SelectMissionPage.module.scss';
import Button from '@components/Button/Button';
import { Add, Info } from '@assets/icons/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { authenticatedApi } from 'src/Api/api';
import MissionItem from './MissionItem/MissionItem';
import { MissionItemType } from './MissionItemType';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import { useTranslation } from 'react-i18next';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';

export default function SelectMissionPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();

  const { caseId } = useParams<{caseId: string}>();
  const state = useLocation().state;
  const navigate = useNavigate();
  const [file, setFile] = useState<{
    clientFirstname: string;
    clientLastname: string;
    clientPhone: string;
    contract: string;
    eventOutcome: string;
    subcover: string;
    missions: Array<MissionItemType>;
  } | null>(null);
  const [infosMessage, setInfosMessage] = useState<Array<string> | null>(null);

  const fetchFile = useCallback(async () => {
    const res = await authenticatedApi.get(`/cases/${caseId}`);
    if (res.data) {
      // navigate to case
      return res.data;
    }
    return null;
  }, [caseId]);

  useEffect(() => {
    (async () => {
      const responseData = state?.getCaseRequestResponse ? state.getCaseRequestResponse : await fetchFile();
      const file = responseData.case;
      const infos = responseData.infos;
      if (!file) {
        navigate("/create-case", {
          state: {
            caseId,
          }
        });
      } else if (file.missions.length === 0) {
        navigate(`/cases/${caseId}/create-mission`, {
          replace: true,
        });
      } else {
        setFile(file);
        setInfosMessage(infos || null);
      }
    })();
  }, [caseId, navigate, fetchFile, state?.getCaseRequestResponse]);

  const onClickAddMission = async () => {
    navigate(`/cases/${caseId}/create-mission`);
  }

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [styles.container, className].join(" "),
      }}
    >

      <HeaderV2 />

      {!file && (
        <FullScreenLoader />
      )}

      {file && (
      <div className={styles.content}>
        <div className={styles.formContainer}>
          <div className={styles.formTitle}>{t('select_mission_title')}</div>

          {infosMessage && (
          <div className={styles.infosContainerContainer}>
            <div className={styles.infosContainer}>
              <Info />
              <div className={styles.infosText}>
                {infosMessage.map((message) => (
                  <div key={message}>{message}</div>
                ))}
              </div>
            </div>
          </div>
          )}

          <div className={[styles.form, stylesForm.form].join(" ")}>

            <div className={stylesForm.formRow}>
              <div className={stylesForm.formCol}>{t('case_number')}: <span className={stylesForm.formValueLeft}>{caseId}</span></div>
              <div className={stylesForm.formCol}><a onClick={(e) => {
                e.preventDefault();
                navigate("/");
                return false;
              }} className={`${stylesForm.formLink} ${styles.formLink} ${styles.caseChange}`}>{t('change_case')}</a></div>
            </div>

            <div className={stylesForm.formRow}>
              <div className={stylesForm.formCol}>
                {t('beneficiary_name_label')}
                <span className={stylesForm.formValueLeft}>{`${file.clientFirstname} ${file.clientLastname}`}</span>
              </div>
            </div>

            <div className={stylesForm.formRow}>
              <div className={stylesForm.formCol}>
                {t('mobile_phone_label')}
                <span className={stylesForm.formValueLeft}>{file.clientPhone ? file.clientPhone.split(" ").slice(1).join("") : ""}</span>
              </div>
            </div>

            <Button
              label={(
                <div className={styles.buttonLabelContainer}>
                  <Add />
                  <div className={styles.buttonLabel}>{t('add_mission_button')}</div>
                </div>
              )}
              type="secondary"
              containerProps={{
                className: `w-full ${styles.addMissionBtn}`,
              }}
              onClick={onClickAddMission}
            />

            <div className={styles.missionItems}>

              {file.missions.map((mission) => (
                <MissionItem
                  key={mission.id}
                  mission={mission}
                  containerProps={{
                    className: styles.missionItem,
                    onClick: () => {
                      navigate(`/cases/${caseId}/missions/${mission.id}`);
                    },
                  }}
                />
              ))}

            </div>
          </div>

        </div>
      </div>
      )}

    </PageContainer>
  )
}