
import { TrainProposalType } from "src/Stores/CurrentMissionTypes/Types";
import { FilterItem, FilterTypeMultiValues, FilterTypeSwitch } from "../types";
import useFilter, { UseFilterReturnType } from "../useFilter";
import { useEffect, useMemo } from "react";
import { useKeyValueStore } from "src/Stores/KeyValueStore";
import { useTranslation } from "react-i18next";


type ItemToFilter = {
  id: string;
  noRemainingCosts: boolean;
  isDirect: boolean;
  flexibilityId: string;
};

type TrainOffersFiltersType = {
  flexibilities: FilterTypeMultiValues;
  noRemainingCosts: FilterTypeSwitch;
  isDirect: FilterTypeSwitch;
}

function itemMatchesValuesForKey(item: FilterItem, filterItemKey: string, values: { [key: string]: boolean }) {

  const filterItemValues = item.dynamicFilters?.[filterItemKey]?.value as string[];
  if (!filterItemValues) {
    return false;
  }

  // If no values selected, we want to show all items
  if (Object.keys(values).filter((value) => values[value]).length === 0) {
    return true;
  }

  return filterItemValues.some((value: string) => {
    return values[value];
  });
}

const filtersConfig: TrainOffersFiltersType = {
  flexibilities: {
    type: 'multiselect',
    label: "Flexibilities",
    options: [],
    displayTags: true,
    getItemValues: (item: ItemToFilter) => {
      return {
        type: 'multi',
        value: [item.flexibilityId]
      };
    },
    itemMatchesValues: (item: FilterItem, values: { [key: string]: boolean }) => {
      return itemMatchesValuesForKey(item, 'flexibilities', values);
    }
  },

  noRemainingCosts: {
    type: 'switch',
    label: "Hide offers with no remaining costs",
    labelTag: "Sans reste à charge",
    displayTags: true,
    getItemValues: (item) => {
      return {
        type: 'switch',
        value: !item.noRemainingCosts
      };
    },
    itemMatchesValues: (item: FilterItem, value: boolean) => {
      // If checked, we want all items
      if (!value) {
        return true;
      }

      return !item.dynamicFilters.noRemainingCosts.value;
    },
    defaultSelectedValue: true,
  },

  isDirect: {
    type: 'switch',
    label: "Afficher les trajets directs uniquement",
    labelTag: "Direct",
    displayTags: true,
    getItemValues: (item) => {
      return {
        type: 'switch',
        value: !!item.isDirect
      };
    },
    itemMatchesValues: (item: FilterItem, value: boolean) => {
      // If not checked, we don't want item that are not direct
      if (!value) {
        return true;
      }
      return item.dynamicFilters.isDirect.value === true;
    },
    defaultSelectedValue: false,
  }
}

function mapToBooleanTrue(values: string[]) {
  return values.reduce((acc: { [key: string]: boolean }, value) => {
    acc[value] = true;
    return acc;
  }, {});
}

export default function useFilterTrainOffers(
  trainProposals?: Array<TrainProposalType>,
  options?: {
    disabledFilters?: Array<keyof TrainOffersFiltersType>;
    defaultValues?: {
      [key: string]: boolean;
    };
    filterStoreKey?: string;
  }
): UseFilterReturnType {

  const { t } = useTranslation();

  const filterNoRemainingCostStoreKey = `filter_noRemainingCosts_${options?.filterStoreKey || ""}`;
  const {
    setKeyValue,
    values: keyValueValues,
  } = useKeyValueStore();
  const filterNoRemainingCostStoreValue = keyValueValues[filterNoRemainingCostStoreKey];

  const filters: TrainOffersFiltersType = useMemo(() => {

    const disabledFilters = options?.disabledFilters || [];

    const allFlexibilities = (trainProposals || []).map(p => p.cabinClasses.map(cabin => (cabin?.offers || []).flat()).flat()).flat().map(o => o.flexibilityId)
      .filter((value, index, self) => self.indexOf(value) === index).map((value) => ({
        label: value,
        value,
      }));

    const flexibilities: TrainOffersFiltersType['flexibilities'] = {
      ...filtersConfig.flexibilities,
      options: allFlexibilities,
      defaultSelectedValues: mapToBooleanTrue(allFlexibilities.map((option) => option.value)),
      disabled: allFlexibilities.length <= 1,
    };

    return {
      isDirect: {
        ...filtersConfig.isDirect,
        disabled: disabledFilters?.includes('isDirect') ||  filtersConfig.isDirect.disabled,
      }, // disabledFilters?.includes('energy') ||
      noRemainingCosts: {
        ...filtersConfig.noRemainingCosts,
        label: t('hideNoRemainingCharge'),
        disabled: disabledFilters?.includes('noRemainingCosts') || filtersConfig.noRemainingCosts.disabled,
        defaultSelectedValue: filterNoRemainingCostStoreValue ?? (options?.defaultValues?.noRemainingCosts ?? filtersConfig.noRemainingCosts.defaultSelectedValue),
      },
      flexibilities: {
        ...flexibilities,
        label: t('flexibilities'),
        disabled: disabledFilters?.includes('flexibilities') || flexibilities.disabled,
      }
    }
  }, [t, filterNoRemainingCostStoreValue, options?.defaultValues?.noRemainingCosts, options?.disabledFilters, trainProposals]);

  const itemsToFilter = useMemo(() => {
    const items: Array<ItemToFilter> = [];
    trainProposals?.forEach((trainProposal) => {
      trainProposal.cabinClasses.forEach((cabinClass) => {
        cabinClass?.offers.forEach((offer) => {
          items.push({
            id: offer.id,
            noRemainingCosts: !offer.remainingCosts,
            isDirect: trainProposal.connectionsCount === 0,
            flexibilityId: offer.flexibilityId,
          });
        });
      });
    });

    return items;
  }, [trainProposals]);

  const useFilterValue = useFilter({
    items: itemsToFilter,
    filters,
  });

  useEffect(() => {
    setKeyValue(filterNoRemainingCostStoreKey, useFilterValue.appliedFilterValues.noRemainingCosts);
  }, [setKeyValue, filterNoRemainingCostStoreKey, useFilterValue.appliedFilterValues.noRemainingCosts]);

  return {
    ...useFilterValue,
    modalProps: {
      ...useFilterValue.modalProps,
      layout: [
        { type: 'filter', filterKey: 'noRemainingCosts' },
        { type: 'filter', filterKey: 'isDirect' },
        { type: 'filter', filterKey: 'flexibilities' },
      ]
    }
  };
}

