import React from 'react';
import styles from './MissionItem.module.scss';
import { MissionItemType } from '../MissionItemType';
import Tag from '@components/Tag/Tag';
import { format } from 'date-fns';
import TravelPath from '@components/TravelPath/TravelPath';
import {Arrival, Car, Taxi, Train, Walk} from '@assets/icons/icons';
import { useTranslation } from 'react-i18next';

export default function MissionItem({
  mission,
  containerProps: { className, ...containerProps } = {},
}: {
  mission: MissionItemType
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();

  const dateCreated = new Date(mission.createdAt);
  const dateUpdated = mission.updatedAt ? new Date(mission.updatedAt) : null;

  return (
    <div data-testid={`missionItem_${mission.id}`} className={[styles.container, className].join(" ")} {...containerProps}>
      <div className={`${styles.lineTitle}`}>
        <div className={styles.title}>{t('mission_title')} {mission.id}</div>
        <div className="flex flex-row gap-2">
          {mission.isSelfCare && (
            <Tag
              label={t('selfcare_tag')}
              color={"#C2C2C2"}
            />
          )}
          <Tag
            label={mission.statusWithLabel.label}
            color={"#FFE8B0"}
          />
        </div>
      </div>
      {dateCreated && (
      <div className={styles.lineDateInfos}>
        <div className={styles.createdAt}>

          {t('created_at_with_time', {
            date: format(dateCreated, "dd/MM/yyyy"),
            time: format(dateCreated, "HH:mm")
          })}
          {mission.createdBy && (
          <div>{t('by')} {mission.createdBy}</div>
          )}
        </div>
        {dateUpdated && (
            <div className={styles.updatedAt}>
              {t('updated_at')} {format(dateUpdated, "dd/MM/yyyy à HH:mm")}
              {mission.lastUpdatedBy && (
                  <div>{t('by')} {mission.lastUpdatedBy}</div>
              )}
            </div>
        )}
      </div>
      )}

      <div className={styles.missionInfosContainer}>

        <div className={styles.formInfos}>
          <div className={styles.line}>
            <div className={styles.label}>{t('contract_label')}</div>
            <div className={styles.value}>{mission.contract}</div>
          </div>
          <div className={styles.line}>
            <div className={styles.label}>{t('event_label')}</div>
            <div className={styles.value}>{mission.eventOutcome}</div>
          </div>
          <div className={styles.line}>
            <div className={styles.label}>{t('guarantee_label')}</div>
            <div className={styles.value}>{mission.subcover}</div>
          </div>
        </div>

        {(mission.mainTransportType || mission.origin || mission.destination) && (
        <div className={styles.missionDetailInfos}>

          <TravelPath
            noHeight100
            travelInfo={{
              left: (
                <div className={styles.pathIconContainer}>
                  <div className={styles.pathIconAndLabel}>
                    {(() => {
                      switch (mission.mainTransportType?.id) {
                        case 'train':
                          return <Train />;
                        case 'vrental':
                          return <Car />;
                        case 'taxi':
                          return <Taxi />;
                        case 'walk':
                          return <Walk />;
                        case 'arrival':
                          return <Arrival />;
                        default:
                          return null;
                      }
                    })()}
                    <div className={styles.pathIconLabel}>{mission.mainTransportType?.label}</div>
                  </div>
                </div>
              ),
              right: (mission.distanceKm !== undefined && mission.distanceKm !== null) ? `${mission.distanceKm}Km` : '',
            }}
            from={{
              labelLeft: "",
              labelRight: mission.origin?.name || "",
            }}
            to={{
              labelLeft: "",
              labelRight: mission.destination?.name || "",
            }}
            containerProps={{
              className: styles.travelPath,
            }}
          />
        </div>
        )}

      </div>
    </div>
  )
}