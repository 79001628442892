import { Arrow } from '@assets/icons/icons';
import styles from './FromToInfos.module.scss';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import TextEllipsisWithPopup from '@components/TextEllipsisWithPopup/TextEllipsisWithPopup';
import { useTranslation } from 'react-i18next';

export default function FromToInfos({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { t } = useTranslation();
  const { mission } = useCurrentMission();
  const infosSearch = mission?.searchData;

  if (!infosSearch) {
    return null;
  }

  return (

      <div className={[styles.fromToContainer, className].join(" ")} {...containerProps}>
        <div className={styles.from}>
          <div className={styles.label}>{t('departure')}</div>
          <TextEllipsisWithPopup
              containerProps={{
                className: styles.value,
              }}
            >{infosSearch.origin.name}</TextEllipsisWithPopup>
        </div>

        {infosSearch.destination && (
        <>
          <div className={styles.separatorDistance}>
            <div className={styles.distanceKm}>{infosSearch.distanceKM} km</div>
            <Arrow className={styles.arrow} />
          </div>

          <div className={styles.to}>
            <div className={styles.label}>{t('arrival')}</div>
            <TextEllipsisWithPopup
              containerProps={{
                className: styles.value,
              }}
            >{infosSearch.destination.name}</TextEllipsisWithPopup>
          </div>
        </>
        )}
      </div>
  )
}