import styles from './HeaderMissionSecondary.module.scss';
import { Chevron, Luggage, Person, SmallLuggage } from '@assets/icons/icons';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import FromToInfos from '../FromToInfos';
import { useRef, useState } from 'react';
import WarrantiesListForm from 'src/Pages/_Components/WarrantiesList/WarrantiesList';
import ContractForm from 'src/Pages/_Components/ContractForm/ContractForm';
import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useConfirmAlert } from 'src/Stores/ConfirmAlertStore';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from "usehooks-ts";
import HeaderSynchroSelfCare from './HeaderSynchroSelfCare';

export default function HeaderMissionSecondary({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { mission, missionContext } = useCurrentMission();
  const infosSearch = mission?.searchData;
  const navigate = useNavigate();
  const { confirm } = useConfirmAlert();

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  if (!infosSearch) {
    return null;
  }

  const displaySelfCareSynchroHeader = missionContext?.isSelfCare && missionContext?.forceLockAction;

  return (
    <div ref={ref} className={[styles.secondaryMissionHeader, className].join(" ")} {...containerProps}>

      {displaySelfCareSynchroHeader && (
        <div className='pb-4'>
          <HeaderSynchroSelfCare />
        </div>
      )}

      <div className={styles.mainContent}>

        <FromToInfos />

        <div className={styles.optionsPreview}>
          <div className={styles.option}>
            <Person />
            <div className={styles.optionLabel}>x {infosSearch.passengerCount}</div>
          </div>
          <div className={styles.option}>
            <Luggage />
            <div className={styles.optionLabel}>{infosSearch.bigBaggageCount}</div>
          </div>
          <div className={styles.option}>
            <SmallLuggage />
            <div className={styles.optionLabel}>{infosSearch.smallBaggageCount}</div>
          </div>
        </div>

        <div className={styles.chevron} onClick={() => {
          setIsOpen(!isOpen);
        }}>
          <Chevron
            className={[styles.chevronIcon, isOpen ? styles.chevronIconOpened : ""].join(" ")}
          />
        </div>
      </div>

      <div className={[styles.expandableContent, isOpen ? styles.expandableContentOpened : ""].join(" ")}>

        <WarrantiesListForm
          containerProps={{
            className: styles.warrantiesList,
          }}
        />

        <div className={styles.contractFormContainer}>
          <ContractForm
            containerProps={{
              className: styles.contractForm,
            }}
            layout={{
              beneficiaryName: true,
              beneficiaryPhone: true,
              licensePlate: true,
              case: true,
              mission: true,
              changeCaseAction: false,
              changeMissionAction: false,
            }}
          />

          <div className={styles.buttonsContainer}>
            <Button
              label={t('change_case')}
              type="primary"
              containerProps={{
                className: styles.button,
              }}
              onClick={async () => {
                confirm({
                  title: t('change_case'),
                  message: t('change_case_confirm'),
                  confirmLabel: t('optionOui'),
                  onConfirm: () => {
                    navigate("/");
                  },
                  cancelLabel: t('optionNon'),
                  onCancel: () => void 0,
                })
              }}
            />

            <Button
              label={t('change_mission_link')}
              type="secondary"
              containerProps={{
                className: styles.button,
              }}
              onClick={() => {
                confirm({
                  title: t('change_mission_link'),
                  message: t('change_mission_confirm'),
                  confirmLabel: t('optionOui'),
                  onConfirm: () => {
                    navigate("./../..");
                  },
                  cancelLabel: t('optionNon'),
                  onCancel: () => void 0,
                })
              }}
            />
          </div>

        </div>

      </div>
    </div>
  )
}