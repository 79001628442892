import { useRef, useState } from "react";
import Button, { ButtonPropsType } from "@components/Button/Button";
import { useOnClickOutside } from "usehooks-ts";
import { IconActions } from "@assets/icons/icons";

export default function MultiActionsButton({
  actions,
  buttonProps
}: {
  actions: Array<{
    label: string;
    onClick: () => any;
  }>;
  buttonProps?: ButtonPropsType
}) {

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });


  return (
    <div ref={ref} className="relative inline-block text-left">
      {/* Main button */}
      <Button
        {...buttonProps}
        onClick={toggleDropdown}
      >
        <IconActions /> 
      </Button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute right-0 z-10 w-44 mt-2 bg-white border border-gray-200 rounded-md shadow-lg">
          <ul className="py-1">
            {actions.map((action, i) => (
              <li key={i}>
                <button
                  onClick={() => {
                    closeDropdown();
                    action.onClick();
                  }}
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                >
                  {action.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}