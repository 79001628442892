import styles from './ListSearchResults.module.scss';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';
import React from 'react';
import { BulkAction, CrudMetadata, EntityItem } from '../../../types';
import { IconChevron } from '@assets/icons/icons';
import ListSearchResultLine from './ListSearchResultLine/ListSearchResultLine';
import MultiActionsButton from '@components/MultiActionsButton/MultiActionsButton';


export default function ListSearchResults({
  metadata,
  isLoading,
  results = [],
  selectedIds = [],
  onSelectId,
  sortedBy,
  sortedDirection,
  onClickSort,
  theadClassName,
  containerProps: { className, ...containerProps } = {},
  onClickAction,
  onClickBulkAction,
}: {
  metadata: CrudMetadata;
  isLoading?: boolean;
  results?: Array<EntityItem>;
  selectedIds?: Array<string>;
  onSelectId?: (id: string) => void
  sortedBy?: string;
  sortedDirection?: 'asc' | 'desc';
  onClickSort?: (fieldId: string) => void;
  theadClassName?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  onClickAction?: (actionKey: string, entity: EntityItem) => void;
  onClickBulkAction?: (bulkAction: BulkAction, uniqueActionId?:string) => any;
}) {

  const { t } = useTranslation();
  const hasResults = results.length > 0;
  let hasActions = false;
  results.some(result => {
    hasActions = !!Object.keys(result.actions || {}).filter(key => key === "update" || key === "delete").length;
    return hasActions;
  });

  let hasNavs = false;
  results.some(result => {
    hasNavs = !!result.navs?.length;
    return hasNavs;
  });

  let hasTagsColumn = false;
  results.some(result => {
    hasTagsColumn = !!result.rowProperties?.isSticky;
    return hasTagsColumn;
  });

  const hasSelectColumn = !!onSelectId;

  const columnsCount = metadata.listView.columns.length + ((hasActions || hasNavs) ? 1 : 0) + (hasTagsColumn ? 1 : 0) + (hasSelectColumn ? 1 : 0);
  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      <table border={0}>
        <thead className={`${theadClassName} ${styles.sticky}`}>
          <tr className={styles.theadRow}>
            {hasSelectColumn && (<th className={styles.theadBulkActions}>

              <MultiActionsButton
                actions={metadata.bulkActions?.map((action) => ({
                  label: action.label,
                  onClick: () => {
                    if (onClickBulkAction) {
                      onClickBulkAction(action);
                    }
                  }
                })) || []}

                buttonProps={{
                  containerProps: {
                    className:styles.buttonActions,
                    disabled: selectedIds.length < 2
                  }
                }}
              />

            </th>)}
            {hasTagsColumn && (<th  className={styles.theadActions}></th>)}
            {metadata.listView.columns.map(col => (
              col.sortable ? (
              <th
                className={styles.sortable}
                onClick={() => onClickSort && onClickSort(col.id)}
                key={col.id}
              >
                <div className={styles.sortableContainer}>
                  {metadata.fields[col.id]?.label}
                  <div className={styles.sortIcon}>
                    <IconChevron className={`${styles.chevronUp} ${(sortedBy === col.id && sortedDirection === 'asc') ? styles.active : null}`} />
                    <IconChevron className={`${styles.chevronDown} ${(sortedBy === col.id && sortedDirection === 'desc') ? styles.active : null}`} />
                  </div>
                </div>
              </th>
              ) : (
              <th key={col.id}>{metadata.fields[col.id]?.label}</th>
              )
            ))}
            {(hasActions || hasNavs) && (<th className={styles.theadActions}></th>)}
          </tr>

          {hasResults ? results.map((result, j) => {
            if (!result.rowProperties?.isSticky) {
              return null;
            }
            return (
              <ListSearchResultLine
                key={j}
                result={ListSearchResultLine}
                metadata={metadata}
                isLoading={isLoading}
                hasTagsColumn={hasTagsColumn}
                selected={selectedIds.includes(result[metadata.idField])}
                onSelect={onSelectId ? () => onSelectId(result[metadata.idField]) : undefined}
                onClickAction={onClickAction}
                onClickBulkAction={onClickBulkAction}
                className={`${styles.resultRow} ${j === results.length - 1 ? styles.lastRow : ''}`}
                hasActions={hasActions}
                hasNavs={hasNavs}
                isSticky
              />
            )
          }) : null}
        </thead>

        {(!hasResults) && (
          <tbody className={styles.noResults}>
            <tr>
              {isLoading ? (
                <td colSpan={columnsCount}>
                  <Skeleton />
                </td>
              ) : (
                <td colSpan={columnsCount}>
                  {t('no_results')}
                </td>
              )}
            </tr>
          </tbody>
        )}

        {hasResults && (
          <tbody>
            {results.map((result, j) => {
              if (result.rowProperties?.isSticky) {
                return null;
              }
              return (
                <ListSearchResultLine
                  key={j}
                  result={result}
                  metadata={metadata}
                  isLoading={isLoading}
                  hasTagsColumn={hasTagsColumn}
                  selected={selectedIds.includes(result[metadata.idField])}
                  onSelect={onSelectId ? () => onSelectId(result[metadata.idField]) : undefined}
                  onClickAction={onClickAction}
                  onClickBulkAction={onClickBulkAction}
                  className={`${styles.resultRow} ${j === results.length - 1 ? styles.lastRow : ''}`}
                  hasActions={hasActions}
                  hasNavs={hasNavs}
                />
              )
            })}
          </tbody>
        )}

      </table>
    </div>
  )
}
