import Button from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import styles from './ModalConfirm.module.scss'
import { useConfirmAlert } from 'src/Stores/ConfirmAlertStore';
import { CheckNotValid } from '@assets/icons/icons';
import { useState } from 'react';
import { Oval } from 'react-loader-spinner';
/*
content="Voulez-vous vraiment modifier la recherche ?"
        confirmText="Oui"
        cancelText="Non"
        onConfirm={() => { }}
        onCancel={() => { }}

*/
export default function ModalConfirm({
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  layout,
  message_type,
}: {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => any;
  onCancel: () => any;
  layout?: 'default' | 'minimal';
  message_type?: 'default' | 'success' | 'error';

}) {

  const { close } = useConfirmAlert();

  const [ loading, setLoading ] = useState(false);

  return (
    <Modal
      title={(
        <div className={styles.modalTitle}>
          {message_type === 'error' && (
            <CheckNotValid />
          )}
          {title}
        </div>
      )}
      containerProps={{
        style: {
          maxWidth: 600,
        },
        className: `${styles.modalConfirmContainer} ${layout ? styles[layout] : ""} ${styles["type_" + (message_type || 'default')]}`
      }}
      onRequestClose={close}
      layout={layout}
    >
      <div className={styles.messageContainer}>
        {message}
      </div>
      <div className={styles.buttonActions}>

        <Button
          label={loading ? (
            <Oval
              color="#fff"
              height={20}
              width={20}
            />
          ) : confirmText}
          onClick={async () => {
            const responseConfirm = onConfirm();
            if (responseConfirm instanceof Promise) {
              setLoading(true);
              await responseConfirm;
            }
            close();
          }}
          colorType={message_type}
        />

        <Button
          label={loading ? (
            <Oval
              color="#fff"
              height={20}
              width={20}
            />
          ) : cancelText}
          onClick={() => {
            onCancel();
            close();
          }}
          colorType={message_type}
          type="secondary"
        />
      </div>
    </Modal>
  )
}