import Button from "@components/Button/Button";
import Tag from "@components/Tag/Tag";
import { useTranslation } from "react-i18next";
import { useCurrentMission } from "src/Stores/CurrentMission";

export default function HeaderSynchroSelfCare() {

  const { t } = useTranslation();
  const { missionContext, callAction } = useCurrentMission();

  return (
    <div className="flex items-center justify-start gap-4 bg-white">
      <Tag
        label={t('selfcare')}
        color={"#C2C2C2"}
        isLarge
      />
      <Button
        containerProps={{
          className: "h-[20px] px-4",
        }}
        onClick={async () => {
          if (!missionContext?.forceLockAction) {
            return;
          }
          await callAction(missionContext?.forceLockAction);
        }}
      >
        {t('take_back_control')}
      </Button>
    </div>
  )
}