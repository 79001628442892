
export default function formatDuration(duration: {value: number; unit: 'minutes' | 'hours'}) {
  if (duration.unit === 'hours') {
    return `${duration.value}h`;
  } else {
    const hours = Math.floor(duration.value / 60);
    const minutes = Math.round(duration.value % 60);
    const paddedMinute = minutes < 10 ? `0${minutes}` : minutes;
    if (hours > 0) {
      return `${hours}${minutes > 0 ? `h${paddedMinute}` : 'h'}`;
    }
    if (minutes <= 1) {
      return `${minutes} min`;
    }

    return `${minutes} mins`;
  }
}